@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --contexify-zIndex: 666;
  --contexify-menu-minWidth: 220px;
  --contexify-menu-padding: 6px;
  --contexify-menu-radius: 6px;
  --contexify-menu-bgColor: rgb(249 250 251);
  --contexify-menu-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  --contexify-menu-negatePadding: var(--contexify-menu-padding);

  --contexify-separator-color: rgba(0, 0, 0, 0.2);
  --contexify-separator-margin: 5px;
  --contexify-itemContent-padding: 6px;
  --contexify-activeItem-radius: 4px;
  --contexify-item-color: rgb(31 41 55);
  --contexify-activeItem-color: #fff;
  --contexify-activeItem-bgColor: rgb(79 70 229) !important;
  --contexify-rightSlot-color: #6f6e77;
  --contexify-activeRightSlot-color: #fff;
  --contexify-arrow-color: #6f6e77;
  --contexify-activeArrow-color: #fff;
}

.contexify_theme-dark {
  --contexify-menu-bgColor: rgba(40, 40, 40, 0.98) !important;
  --contexify-separator-color: #4c4c4c;
  --contexify-item-color: #fff !important;
  --contexify-activeItem-bgColor: rgb(79 70 229) !important;
}

.flamegraphHint-Container .flamegraphHint {
  @apply shadow-allSideOuter bg-white text-gray-800 dark:bg-gray-900 dark:text-gray-300;
}

.flamegraphHint-Container .react-tooltip-arrow {
  @apply shadow-[1px_1px_4px_0px_rgba(0,0,0,0.01)];
}

.HW_badge_cont {
  position: absolute !important;
  top: -9px;
  right: -14px;
}

.HW_badge {
  position: absolute !important;
  top: -9px;
  right: -14px;
}

.HW_badge.HW_visible {
  background-color: #4c68f6 !important;
}

.HW_badge.HW_softHidden {
  opacity: 0 !important;
}
